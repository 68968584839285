import React, { Component } from "react";
import Page3Component from "../ReusableComponent/Page3Component";
import data from "../../data.json";
import { Container } from "react-bootstrap";
export default class CampusComponent extends Component {
  render() {
    return (
      <div>
        <Container>
          <Page3Component data={data.campus} />
        </Container>
      </div>
    );
  }
}
