import React, { Component } from 'react'
import Page2Component from '../ReusableComponent/Page2Component'
import PageListComponent from '../ReusableComponent/PageListComponent'
import PageList2Component from '../ReusableComponent/PageList2Component'
import data from '../../data.json'
import { Container } from 'react-bootstrap'
import Page2ComponentAFU from '../ReusableComponent/Page2ComponentAFU'

export default class AFUComponent extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Page2Component data={data.afu}/>
                    <PageListComponent data={data.afu2}/>
                    <PageList2Component data={data.afu3}/>
                    <Page2ComponentAFU data={data.afu4}/>
                </Container>
            </div>
        )
    }
}
