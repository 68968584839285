import React, { Component } from "react";
import "../../styles/Donate.css";
import { Container } from "react-bootstrap";
export default class DonateComponent extends Component {
  render() {
    return (
      <div>
        <Container>
          <div className="donate-container">
            <h4 className="donate-text">
              Vos dons sont déductibles à hauteur de 66% de votre impôt sur le
              revenu.
            </h4>
            <h6 className="donate-text">
              L’AFU ne repose que sur des bénévoles. Les frais de fonctionnement
              représentent environ de 2% des dons reçus. Nos comptes sont
              vérifiés par le cabinet NATAF et certifiés par la société AECD,
              commissaire aux comptes.
            </h6>
            <h6 className="donate-text">
              {" "}
              Faites un don par chèque à l'adresse suivante: 27 rue du docteur
              Finlay 75015 Paris{" "}
            </h6>
            <h6 className="donate-text"> Ou en ligne via Hello Asso</h6>
            <h6 className="donate-text">
              Les dons en francs suisses peuvent faire l’objet d’une déduction
              fiscale via la Fondation suisse Allegri Philantropy. Pour ce
              faire, nous contacter à l’adresse suivante:{" "}
              <a
                className="footer-mail"
                href="mailto:haiti-afu@orange.fr?subject=feedback"
              >
                audelangloism@gmail.com
              </a>
            </h6>
          </div>
          <iframe
            title="don-afu-hello-asso"
            className="donate-HA"
            id="haWidget"
            allowtransparency="true"
            src="https://www.donnerenligne.fr/afu/faire-un-don"
          ></iframe>
        </Container>
      </div>
    );
  }
}
