import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/PagePreview.css';
import { Link } from 'react-router-dom';

export default class PagePreviewListComponent extends Component {
    render() {
        return (
            <div>
                <h1 className="page-preview-title">{this.props.data.title}</h1>
                <Col>
                    <Row>
                        <img alt={this.props.data.img_home} className="preview-image" src={this.props.data.img_home}></img>
                    </Row>
                    <Row className="preview-txtContainer">
                        <div className="preview-list">
                            {this.props.data.list.map((item) =>
                                <p key={item} dangerouslySetInnerHTML={{__html: item}}/>
                            )}
                        </div>
                        <span><Link className="preview-button" to={this.props.data.to}>En Savoir Plus</Link></span>
                    </Row>
                </Col>
            </div>
        )
    }
}
