import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Page4Component extends Component {
  render() {
    return (
      <div>
        <h1 className="page-preview-title">{this.props.data.title}</h1>
        <Row className="afu-row">
          <Col className="afu-col">
            <img
              alt={this.props.data.img}
              className="preview-image"
              src={this.props.data.img}
            ></img>
          </Col>
          <Col className="afu-col">
            <p dangerouslySetInnerHTML={{ __html: this.props.data.txt }} />
          </Col>
        </Row>
        <Row className="afu-row">
          <Col className="afu-col">
            <img
              alt={this.props.data.img2}
              className="preview-image img2"
              src={this.props.data.img2}
            ></img>
          </Col>
          <Col className="afu-col">
            <img
              alt={this.props.data.img3}
              className="preview-image img2"
              src={this.props.data.img3}
            ></img>
          </Col>
        </Row>
      </div>
    );
  }
}
