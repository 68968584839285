import React, { Component } from 'react'
import Page2Component from '../ReusableComponent/Page2Component'
import data from '../../data.json'
import { Container } from 'react-bootstrap'

export default class ProComponent extends Component {
    render() {
        return (
            <div>
                <Container>
                    <Page2Component data={data.pro}/>
                </Container>
            </div>
        )
    }
}
