import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/Footer.css";

export default class FooterComponent extends Component {
  render() {
    return (
      <div className="footer">
        <Container>
          <Row>
            <Col>
              <h2>Nous Contacter</h2>
              <address className="footer-address">
                Adresse: 27 rue du docteur Finlay, 75015 Paris <br />
                <a
                  className="footer-mail"
                  href="mailto:haiti-afu@orange.fr?subject=feedback"
                >
                  Mail: haiti-afu@orange.fr
                </a>{" "}
                <br />
                <a className="footer-tel" href="tel:+33676492433">
                  Tel: 06 76 49 24 33
                </a>
              </address>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
