import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../styles/PagePreview.css';
import { Link } from 'react-router-dom';

export default class PagePreviewComponent extends Component {
    render() {
        return (
            <div>
                <h1 className="page-preview-title">{this.props.data.title}</h1>
                <Row style={this.props.data.pos} className="page-preview-container afu-row">
                    <Col className="afu-col">
                        <img alt={this.props.data.img_home} className="preview-image" src={this.props.data.img_home}></img>
                    </Col>
                    <Col className="afu-col">
                        <div className="box">
                            <p className="preview-txt" dangerouslySetInnerHTML={{__html: this.props.data.txt_home}}/>
                            <span><Link className="preview-button" to={this.props.data.to}>En Savoir Plus</Link></span>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
