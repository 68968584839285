import React, { Component } from "react";
import Page5Component from "../ReusableComponent/Page5Component";
import data from "../../data.json";
import { Container } from "react-bootstrap";

export default class EcolesComponent extends Component {
  render() {
    return (
      <div>
        <Container>
          <Page5Component data={data.ecole} />
        </Container>
      </div>
    );
  }
}
