import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "../styles/Nav.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import AFUComponent from "./PagesComponent/AFUComponent";
import CampusComponent from "./PagesComponent/CampusComponent";
import CentresComponent from "./PagesComponent/CentresComponent";
import EcolesComponent from "./PagesComponent/EcolesComponent";
import ProComponent from "./PagesComponent/ProComponent";
import PFIComponent from "./PagesComponent/PFIComponent";
import HomeComponent from "./PagesComponent/HomeComponent";
import DonateComponent from "./PagesComponent/DonateComponent";

export default class NavBarComponent extends Component {
  render() {
    return (
      <Router>
        <Navbar bg="white" expand="lg" className="afu-nav">
          <Navbar.Brand>
            <Link to="/home">
              <img
                alt="Afu-logo"
                className="logo d-inline-block align-top"
                src="img/logo.png"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto afu-navbar">
              <Link className="afu-button-nav" to="/afu">
                AFU en Haïti
              </Link>
              <Link className="afu-button-nav" to="/campus">
                Campus Agricole
              </Link>
              <Link className="afu-button-nav" to="/centres">
                Centres de Nutrition
              </Link>
              <Link className="afu-button-nav" to="/ecoles">
                Écoles
              </Link>
              <Link className="afu-button-nav" to="/pro">
                Formation Professionnelle
              </Link>
              <Link className="afu-button-nav" to="/pfi">
                Petits Frères et Soeurs de L'incarnation
              </Link>
              <Link className="afu-button-nav-don" to="/don">
                Je fais un don
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route path="/home">
            <HomeComponent />
          </Route>
          <Route path="/afu">
            <AFUComponent />
          </Route>
          <Route path="/campus">
            <CampusComponent />
          </Route>
          <Route path="/centres">
            <CentresComponent />
          </Route>
          <Route path="/ecoles">
            <EcolesComponent />
          </Route>
          <Route path="/pro">
            <ProComponent />
          </Route>
          <Route path="/pfi">
            <PFIComponent />
          </Route>
          <Route path="/don">
            <DonateComponent />
          </Route>
          <Route path="">
            <Redirect to="/home" />
          </Route>
          <Route path="/">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </Router>
    );
  }
}
