import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class PageList2Component extends Component {
    render() {
        return (
            <div>
                <h1 className="page-preview-title">{this.props.data.title}</h1>
                <Row className="afu-row">
                    <Col className="afu-list afu-col">
                        {this.props.data.list.map((item) =>
                            <p key={item} dangerouslySetInnerHTML={{__html: item}}/>
                        )}
                    </Col>
                </Row>
            </div>
        )
    }
}
