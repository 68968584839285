import React, { Component } from 'react'
import '../../styles/PagePreview.css';

export default class FileListComponent extends Component {
    render() {
        return (
            <div>
                <h1 className="page-preview-title">{this.props.data.title}</h1>
                <div className="d-flex flex-column justify-content-between flex-md-row">
                    {this.props.data.list.map((item) =>
                        <div key={item.date} className="d-flex flex-column activity-col">
                            <div>
                                <p className="activity-date">{item.date}</p>
                                <img alt={item.img} className="activity-img" src={item.img}></img>
                            </div>
                            <div className="activity-btn">
                                <span><a className="preview-button" target="_blank" rel="noopener noreferrer" href={item.file} >Lire</a></span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}
