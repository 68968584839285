import React from 'react';
import './styles/App.css';
import NavBarComponent from './Components/NavBarComponent'
import FooterComponent from './Components/FooterComponent'
import data from './data.json'
function App() {
  return (
    <div className="App">
        <NavBarComponent/>
        <FooterComponent data={data.footer}/>
    </div>
  );
}

export default App;
