import React, { Component } from "react";
import PagePreviewComponent from "../ReusableComponent/PagePreviewComponent";
import PagePreviewListComponent from "../ReusableComponent/PagePreviewListComponent";
import FileListComponent from "../ReusableComponent/FileListComponent";
import data from "../../data.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

export default class HomeComponent extends Component {
  render() {
    return (
      <div>
        <Container>
          <PagePreviewComponent data={data.afu} />
          <div className="box2 img2">
            <h5>
              Dans un contexte politique et économique très dégradé, la
              Fraternité de l’Incarnation et l’AFU se battent pour pérenniser
              les réalisations accomplies, continuer à nourrir des enfants pour
              mieux les éduquer et former des entrepreneurs agricoles.
              <br />
              <br /> Le problème de la faim s'est aggravé ces dernières années
              et concerne aujourd'hui 4 millions d'Haïtiens.
            </h5>
          </div>
          <PagePreviewComponent data={data.campus} />
          <PagePreviewComponent data={data.centre} />
          <Row className="afu-row">
            <Col className="afu-col">
              <PagePreviewListComponent data={data.ecole} />
            </Col>
            <Col className="afu-col">
              <PagePreviewListComponent data={data.pro} />
            </Col>
          </Row>
          <PagePreviewComponent data={data.pfi} />
          <FileListComponent data={data.activity} />
        </Container>
      </div>
    );
  }
}
